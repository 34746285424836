import React from 'react'

class Footer extends React.Component {
  render() {
    return (
      <section id="footer">
        <ul className="icons">
          {/*<li><a href="#" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li>*/}
          <li><a href="https://www.facebook.com/hanlieclaaseninteriors"
                 target="_blank"
                 rel="noreferrer"
                 style={{ display: 'table-cell' }}
                 className="icon alt fa-facebook">
            <span className="label">Facebook</span>
          </a></li>
          <li><a href="mailto:info@hcint.co.za"
                 style={{ display: 'table-cell' }}
                 className="icon alt fa-envelope"><span className="label">Email</span></a></li>
          <li><a href="https://www.instagram.com/hanlieclaaseninteriors"
                 target="_blank"
                 rel="noreferrer"
                 style={{ display: 'table-cell' }}
                 className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
          {/*<li><a href="#" className="icon alt fa-github"><span className="label">GitHub</span></a></li>*/}

        </ul>
        <ul className="copyright">
          <li>&copy; hanlie claasen interiors 2020</li>
        </ul>
      </section>
    )
  }
}

export default Footer
