import React from 'react'
import websitelogo from '../assets/images/websitelogo.jpg'
import Nav from './Nav.js'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ismobile: false,
    }
  }

  componentDidMount() {
    this.setState({ ismobile: (window.innerWidth <= 900) })
  }

  render() {
    if (this.props.ishome) {

      return (
        <section id="header">
          <div className="inner">
            <span className="icon major">
              <img
                draggable={false}
                style={{
                  pointerEvents: 'none',
                  border: '1px solid black', borderRadius: '50%',
                  width: 'auto',
                  maxWidth: this.state.ismobile ? '80%' : '100%'
                }}
                src={websitelogo} alt="" />
            </span>
            {/*<span className="icon major fa-cloud"></span>*/}
            <h1><strong>hanlie claasen interiors</strong></h1>
            <p><strong>...a home should be a reflection of its inhabitants...<br /></strong></p>
            <p><strong>
              ...a workspace should encourage creative productivity...</strong></p>
            <Nav ishome={true}/>
          </div>
        </section>
      )
    } else {
      return (<section id="header">
        <div className="inner">
            <div className="grid-wrapper" style={{
                display: 'flex',
                alignItems: 'left',
                justifyContent: 'left',
              }}>
              <div className="col-1" >
                <img
                  style={{
                    pointerEvents: 'none',
                    border: '1px solid black', borderRadius: '50%',
                    width: 'auto',
                    aspectRatio: '1:1',
                    maxWidth: this.state.ismobile ? '40%' : '30%',
                    align: 'center'
                  }}
                  src={websitelogo} alt="" />
              </div>
              <div className="col-3">
              <h2><strong>hanlie claasen interiors</strong></h2>
              </div>
            </div>
            <div > <Nav ishome={false}/> </div>
        </div>
      </section>
      )
    }
  }
}

export default Header
