import React from 'react'

const Nav = (props) => {
    if (props.ishome) {
        return (<><ul className="actions">
            {/* <li><a href="/hfdg2020" className="button dark">HF-Designers Guild 2020</a></li> */}
            <li><a href="/sip" className="button dark">Small Interior Products</a></li>
            <li><a href="/#aboutus" className="button scrolly">About us</a></li>
            <li><a href="/#team" className="button scrolly">The Team</a></li>
            <li><a href="/#projects" className="button scrolly">Projects</a></li>
            <li><a href="/#footer" className="button scrolly">We're Social</a></li>

        </ul></>)
    } else {
        return (<><ul className="actions">
            <li><a href="/" className="button scrolly">Return to Home Page</a></li>
        </ul></>)
    }
}

export default Nav;
